import ky from 'ky';

export const defaultky = ky;

const AuthorizationHeader = (request) => {
  request.headers.set('Authorization', process.env.VUE_APP_RJ_ACCESS_TOKEN);
};

export const http = ky.extend({
  credentials: 'include',
  retry: 1,
  prefixUrl: process.env.VUE_APP_RJ_API_URL,
  throwHttpErrors: true,
  hooks: {
    beforeRequest: [AuthorizationHeader],
  },
});

<template>
  <vx-card class="py-2">
    <template #no-body>
      <img
        :src="image"
        alt="content-img"
        class="object-cover w-full card-img-top"
      >
    </template>

    <div class="text-center">
      <h1>{{ funnel.offerTitle }}</h1>
      <p class="mt-3 text-lg">
        {{ funnel.offerBody }}
      </p>

      <div class="mt-6">
        <h3>Click to Share</h3>

        <div class="flex items-center justify-center space-x-8 py-4">
          <!-- Facebook Share -->
          <share-network
            v-if="networks.includes('Facebook')"
            tag="div"
            network="facebook"
            :url="ogShareUrl"
            :title="funnel.offerShareSubject"
            :description="funnel.offerShareBody"
            class="cursor-pointer"
            @open="recordShareMethod"
          >
            <span
              class="block mx-auto w-12 h-12 flex items-center justify-center rounded-full text-white"
              style="background: #3b5998;"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
            </span>
            <span class="text-xs text-center">Facebook</span>
          </share-network>

          <!-- SMS Share -->
          <share-network
            v-if="networks.includes('SMS')"
            tag="div"
            network="sms"
            :url="shareUrl"
            :title="funnel.offerShareSubject"
            :description="funnel.offerShareBody"
            class="cursor-pointer"
            @open="recordShareMethod"
          >
            <span
              class="block mx-auto w-12 h-12 flex items-center justify-center rounded-full text-white"
              style="background: #28c76f;"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M19 2c0-1.104-.896-2-2-2h-10c-1.104 0-2 .896-2 2v20c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2v-20zm-8.5 0h3c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm1.5 20c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm5-3h-10v-14.024h10v14.024z" />
              </svg>
            </span>
            <span class="text-xs text-center">SMS</span>
          </share-network>

          <!-- Email Share -->
          <share-network
            v-if="networks.includes('Email')"
            tag="div"
            network="email"
            :url="shareUrl"
            :title="funnel.offerShareSubject"
            :description="funnel.offerShareBody"
            class="cursor-pointer"
            @open="recordShareMethod"
          >
            <span
              class="block mx-auto w-12 h-12 flex items-center justify-center rounded-full text-white"
              style="background: #3caec8;"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z" />
              </svg>
            </span>
            <span class="text-xs text-center">Email</span>
          </share-network>

          <share-network
            v-if="networks.includes('Twitter')"
            tag="div"
            network="twitter"
            :url="shareUrl"
            :title="funnel.offerShareBody"
            class="cursor-pointer"
            @open="recordShareMethod"
          >
            <span
              class="block mx-auto w-12 h-12 flex items-center justify-center rounded-full text-white"
              style="background: #1DA1F2;"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
              ><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" /></svg>
            </span>
            <span class="text-xs text-center">Twitter</span>
          </share-network>
        </div>

        <a
          href="#"
          class="text-gray-600"
          @click.prevent="showDetails"
        >
          Offer Details
        </a>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { ShareNetwork } from 'vue-social-sharing';
import { http } from '@/ky';

export default {
  name: 'RjReferralOffer',

  components: { ShareNetwork },

  props: {
    company: {
      type: Object,
      required: true,
    },

    survey: {
      type: Object,
      required: true,
    },

    contact: {
      type: Object,
      required: true,
    },

    funnel: {
      type: Object,
      required: true,
    },
  },

  computed: {
    baseUrl() {
      return process.env.VUE_APP_RJ_BASE_URL;
    },

    image() {
      return `${process.env.VUE_APP_RJ_OFFER_IMAGES_URL}/${this.funnel.companyId}/${this.funnel.offerImage}`;
    },

    networks() {
      const { offerShareMethod1, offerShareMethod2, offerShareMethod3 } = this.funnel;

      return [offerShareMethod1, offerShareMethod2, offerShareMethod3].filter((nt) => nt !== null);
    },

    shareUrl() {
      const { url } = this.survey;

      return `${process.env.VUE_APP_RJ_BASE_SHARE_URL}/${url}`;
    },

    ogShareUrl() {
      const {
        offerShareSubject = '',
        offerShareBody = '',
        offerImage = '',
        offerUrl = '',
      } = this.funnel;

      const { companyId } = this.survey;

      return encodeURI(`${this.baseUrl}/og/${this.survey.url}?offerShareSubject=${offerShareSubject}&offerShareBody=${offerShareBody}&companyId=${companyId}&offerImage=${offerImage}&offerUrl=${offerUrl}`);
    },
  },

  methods: {
    recordShareMethod(method) {
      const payload = {
        id: this.survey.id,
        selectedOfferShareMethod: method,
        status: 'Shared',
      };

      http.patch(`surveys/${this.survey.id}`, { json: payload });
    },

    showDetails() {
      this.$vs.dialog({
        color: 'primary',
        title: 'Offer Details',
        iconPack: 'feather',
        closeIcon: 'icon-x',
        text: this.funnel.offerDetails,
        acceptText: 'Close',
      });
    },
  },
};
</script>
